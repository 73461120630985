'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import { headerHeight } from '../constants/size'
import { pagesPath } from '../utils/$path'

import type { AxiosError } from '@atnd/axios'

export default function ErrorPage({
	error,
}: {
	error: Error & { digest?: string }
}) {
	useEffect(() => {
		console.error(error)
		Sentry.addBreadcrumb({
			message: error.message,
			data: {
				cause: error.cause,
				name: error.name,
				stack: error.stack,
				digest: error.digest,
			},
			category: 'unknown',
		})
	}, [error])

	const axiosError = error as AxiosError
	const statusCode = axiosError.response?.status
	const errorMessage = getErrorMessage(statusCode)

	return (
		<div style={containerStyle}>
			<div style={headerStyle}>{errorMessage.title}</div>
			<div style={subheaderStyle}>{errorMessage.description}</div>
			{error.cause === authErrorCause && (
				<a href={pagesPath.login.$url().pathname} style={buttonStyle}>
					ログイン画面へ
				</a>
			)}
		</div>
	)
}

const getErrorMessage = (statusCode?: number) => {
	switch (statusCode) {
		case 400:
			return {
				title: `Bad Request`,
				description: 'リクエストが不正です',
			}
		case 401:
			return {
				title: `Unauthorized`,
				description: '認証が必要です',
			}
		case 403:
			return {
				title: `Forbidden`,
				description: 'アクセスが禁止されています',
			}
		case 404:
			return {
				title: `Not Found`,
				description: 'リソースが見つかりません',
			}
		case 500:
			return {
				title: `Internal Server Error`,
				description: 'サーバーでエラーが発生しました',
			}
		case 503:
			return {
				title: `Service Unavailable`,
				description: 'サービスが一時的に使用不可です',
			}
		default:
			return {
				title: `Unknown Error`,
				description: 'エラーが発生しました',
			}
	}
}

const containerStyle = {
	height: `calc(100dvh - ${headerHeight}px)`,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
} as const

const headerStyle = {
	fontSize: '72px',
	fontWeight: 'bold',
	color: 'cadetblue',
}

const subheaderStyle = {
	fontSize: '24px',
	color: '#757575',
}

const buttonStyle = {
	marginTop: '16px',
	padding: '10px 20px',
	backgroundColor: '#teal',
	color: '#fff',
	fontSize: '16px',
	borderRadius: '4px',
	textDecoration: 'none',
	cursor: 'pointer',
	transition: 'background-color 0.3s',
}

const authErrorCause = 'token_expired'
