const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  _clinicAlias: (clinicAlias: string | number) => ({
    "patients": {
      _patientId: (patientId: string | number) => ({
        "health_check": {
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/[patientId]/health-check' as const, query: { clinicAlias, patientId }, hash: url?.hash, path: `/${clinicAlias}/patients/${patientId}/health-check${buildSuffix(url)}` })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/[patientId]' as const, query: { clinicAlias, patientId }, hash: url?.hash, path: `/${clinicAlias}/patients/${patientId}${buildSuffix(url)}` })
      }),
      "reservations": {
        _reservationId: (reservationId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients/reservations/[reservationId]' as const, query: { clinicAlias, reservationId }, hash: url?.hash, path: `/${clinicAlias}/patients/reservations/${reservationId}${buildSuffix(url)}` })
        })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/patients' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/patients${buildSuffix(url)}` })
    },
    "reservations": {
      _date: (date: string | number) => ({
        "@tabs": {
          "chart": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/chart' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/chart${buildSuffix(url)}` })
          },
          "in_order": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/in-order' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/in-order${buildSuffix(url)}` })
          },
          "memo": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/memo' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/memo${buildSuffix(url)}` })
          },
          "new_users": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/new-users' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/new-users${buildSuffix(url)}` })
          },
          "slots": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/slots' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/slots${buildSuffix(url)}` })
          },
          "tentative": {
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]/tentative' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}/tentative${buildSuffix(url)}` })
          }
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/[date]' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/${date}${buildSuffix(url)}` })
      }),
      "calendar": {
        _date: (date: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/calendar/[date]' as const, query: { clinicAlias, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/calendar/${date}${buildSuffix(url)}` })
        })
      },
      "slots": {
        _ccid: (ccid: string | number) => ({
          _date: (date: string | number) => ({
            $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/reservations/slots/[ccid]/[date]' as const, query: { clinicAlias, ccid, date }, hash: url?.hash, path: `/${clinicAlias}/reservations/slots/${ccid}/${date}${buildSuffix(url)}` })
          })
        })
      }
    },
    "settings": {
      "basic_info": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/settings/basic-info' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/settings/basic-info${buildSuffix(url)}` })
      },
      "operation_hours": {
        $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/settings/operation-hours' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/settings/operation-hours${buildSuffix(url)}` })
      }
    },
    "upload_healthchecks": {
      $url: (url?: { hash?: string }) => ({ pathname: '/[clinicAlias]/upload-healthchecks' as const, query: { clinicAlias }, hash: url?.hash, path: `/${clinicAlias}/upload-healthchecks${buildSuffix(url)}` })
    }
  }),
  "login": {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash, path: `/login${buildSuffix(url)}` })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash, path: `/${buildSuffix(url)}` })
};

export type PagesPath = typeof pagesPath;
